import React from 'react';

import styles from './loadingIcon.module.scss';

const LoaderStyle = {
  Blue: 'blue',
  White: 'white'
};

const Size = {
  small: 'small',
  medium: 'medium',
  large: 'large'
};
const LoadingIcon = props => {
  const { style, size } = props;
  return (
    <>
      {style === LoaderStyle.Blue && size === Size.small && (
        <div className={styles.loaderAnimation} />
      )}
      {style === LoaderStyle.Blue && size === Size.medium && (
        <div className={styles.loaderAnimationMedium} />
      )}
      {style === LoaderStyle.Blue && size === Size.large && (
        <div className={styles.loaderAnimationLarge} />
      )}
      {style === LoaderStyle.White && size === Size.small && (
        <div className={styles.loaderAnimationWhite} />
      )}
      {style === LoaderStyle.White && size === Size.medium && (
        <div className={styles.loaderAnimationMediumWhite} />
      )}
      {style === LoaderStyle.White && size === Size.large && (
        <div className={styles.loaderAnimationLargeWhite} />
      )}
    </>
  );
};
export { LoadingIcon };
