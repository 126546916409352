import React from 'react';

import styles from './loading.module.scss';
import { LoadingIcon } from './LoadingIcon/LoadingIcon';

const Loading = props => {
  const { dotStyle, size } = props;

  const getColor = color => {
    switch (color) {
      case 'BlueOnWhite':
        return { dotColor: 'blue', backgroundColor: 'white' };
      case 'BlueOnLightBlue':
        return { dotColor: 'blue', backgroundColor: 'light-blue' };
      case 'WhiteOnGradientBlue':
        return { dotColor: 'white', backgroundColor: 'gradient-blue' };
      default:
        return { dotColor: 'blue', backgroundColor: 'white' };
    }
  };

  const getSize = dotSize => {
    switch (dotSize) {
      case 'small':
        return 'small';
      case 'medium':
        return 'medium';
      case 'large':
        return 'large';
      default:
        return 'medium';
    }
  };

  return (
    <div className={styles.loadingContainer}>
      {getColor(dotStyle).backgroundColor === 'light-blue' && (
        <div className={styles.iconLightBlueBackground}>
          <LoadingIcon
            style={getColor(dotStyle).dotColor}
            size={getSize(size)}
          />
        </div>
      )}
      {getColor(dotStyle).backgroundColor === 'gradient-blue' && (
        <div className={styles.iconGradientBlueBackground}>
          <LoadingIcon
            style={getColor(dotStyle).dotColor}
            size={getSize(size)}
          />
        </div>
      )}
      {getColor(dotStyle).backgroundColor === 'white' && (
        <div className={styles.iconWhiteBackground}>
          <LoadingIcon
            style={getColor(dotStyle).dotColor}
            size={getSize(size)}
          />
        </div>
      )}
    </div>
  );
};

export { Loading };
