// Remember when making changes to this in dev to restart the server
module.exports = {
  siteData: {
    page: {
      site: 'gemf-web',
      siteCountry: 'nz'
    }
  },
  pageDataExceptions: [
    {
      pathMatch: '/',
      productid: [
        'PLNZLF-WEB', // personal loans
        'MLNZLF-WEB', // motor loans
        'CCNZGEM', // gem visa
        'BPNZLFIST' // buy now pay later NZ
      ]
    },
    {
      pathMatch: '/loans/',
      pagetype: 'category-page',
      sitesection: 'loans',
      productid: ['PLNZLF-WEB', 'MLNZLF-WEB']
    },
    {
      pathMatch: '/loans/personal-loans/',
      pagetype: 'product-details-page',
      sitesection: 'loans',
      subsection: 'personal-loans',
      productid: ['PLNZLF-WEB']
    },
    {
      pathMatch: '/loans/car-loans/',
      pagetype: 'product-details-page',
      sitesection: 'loans',
      subsection: 'motor-loans',
      productid: ['MLNZLF-WEB']
    },
    {
      pathMatch: '/loans/debt-consolidation-loans/',
      pagetype: 'product-details-page',
      sitesection: 'loans',
      subsection: 'debt-consolidation-loans',
      productid: ['PLNZLF-WEB']
    },
    {
      pathMatch: '/loans/home-renovation-loans/',
      pagetype: 'product-details-page',
      sitesection: 'loans',
      subsection: 'home-renovation-loans',
      productid: ['PLNZLF-WEB']
    },
    {
      pathMatch: '/loans/travel-loans/',
      pagetype: 'product-details-page',
      sitesection: 'loans',
      subsection: 'travel-loans',
      productid: ['PLNZLF-WEB']
    },
    {
      pathMatch: '/loans/wedding-loans/',
      pagetype: 'product-details-page',
      sitesection: 'loans',
      subsection: 'wedding-loans',
      productid: ['PLNZLF-WEB']
    },
    {
      pathMatch: '/loans/loan-repayment-calculator/',
      pagetype: 'calculator-page',
      sitesection: 'loans',
      subsection: 'tools-and-calculators',
      productid: ['PLNZLF-WEB']
    },
    {
      pathMatch: '/loans/car-loan-repayment-calculator/',
      pagetype: 'calculator-page',
      sitesection: 'loans',
      subsection: 'tools-and-calculators',
      productid: ['MLNZLF-WEB']
    },
    {
      pathMatch: '/loans/budget-planner/',
      pagetype: 'calculator-page',
      sitesection: 'loans',
      subsection: 'budget-planner',
      productid: ['PLNZLF-WEB', 'MLNZLF-WEB']
    },
    {
      pathMatch: '/loans/apply/',
      sitesection: 'loans',
      subsection: 'application',
      productid: ['PLNZLF-WEB']
    },
    {
      pathMatch: '/credit-cards/gem-visa-card/',
      pagetype: 'product-details-page',
      sitesection: 'cards',
      productid: ['CCNZGEM']
    },
    {
      pathMatch: '/credit-cards/gem-visa-card/acquired-at-apple/',
      pagetype: 'product-details-page',
      sitesection: 'cards',
      productid: ['CCNZGEM']
    },
    {
      pathMatch: '/credit-cards/gem-essential/',
      pagetype: 'product-details-page',
      sitesection: 'cards',
      productid: ['CCNZOCV']
    },
    {
      pathMatch: '/credit-cards/gem-creditline-card/',
      pagetype: 'product-details-page',
      sitesection: 'cards',
      productid: ['CCNZCLN']
    },
    {
      pathMatch: '/credit-cards/interest-free-offers/',
      sitesection: 'cards',
      productid: ['CCNZGEM']
    },
    {
      pathMatch: '/credit-cards/become-a-partner/',
      sitesection: 'cards',
      productid: ['CCNZGEM']
    },
    {
      pathMatch: '/credit-cards/terms-and-conditions/',
      sitesection: 'cards',
      productid: ['CCNZGEM', 'CCNZCLN']
    },
    {
      pathMatch: '/credit-cards/where-to-shop/',
      sitesection: 'cards',
      productid: ['CCNZGEM']
    },
    {
      pathMatch: '/about-us/',
      sitesection: 'about-us'
    },
    {
      pathMatch: '/about-us/our-company/',
      sitesection: 'about-us',
      subsection: 'our-company'
    },
    {
      pathMatch: '/forms/',
      pagetype: 'category-page',
      productid: ['PLNZLF-WEB', 'CCNZGEM', 'CCNZCLN', 'CCNZOCV']
    },
    {
      pathMatch: '/calculators-and-tools/',
      pagetype: 'category-page',
      sitesection: 'calculators-and-tools'
    },
    {
      pathMatch: '/repayment-calculator/',
      pagetype: 'calculator-page',
      sitesection: 'calculators-and-tools',
      productid: ['CCNZGEM']
    },
    {
      pathMatch: '/upload/',
      pagetype: 'upload-page',
      sitesection: 'cards'
    },
    {
      pathMatch: '/genoapay/',
      pagetype: 'product-details-page',
      productid: ['BPNZLFIST']
    },
    {
      pathMatch: '/search/',
      pagetype: 'search-results-page'
    },
    {
      pathMatch: '/upload/error/',
      pagetype: 'error-page',
      sitesection: 'error'
    },
    {
      pathMatch: '/pay-it-faster/',
      productid: ['PLNZLF-WEB']
    },
    {
      pathMatch: '/credit-cards/gem-visa-card/campaign/spend-and-get/',
      productid: ['CCNZGEM']
    },
    {
      pathMatch: '/brokers/',
      pagetype: 'broker-page',
      sitesection: 'brokers',
      sitesubsection: 'brokers',
      productid: ['PLNZLF-WEB', 'MLNZLF-WEB']
    },
  ]
};
